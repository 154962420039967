<template>
  <v-row class="d-flex justify-center align-center h-100">
    <v-col cols="12" no-gutters>
      <v-row no-gutters class="pt-7">
        <v-btn class="text-none platform-access-button" @click="goBackToLogin">
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $t('login.back') }}
        </v-btn>
      </v-row>

      <v-row no-gutters class="welcome-text">
        {{ $t('login.error_release_date', { date: releaseDate }) }}
      </v-row>
      <v-row no-gutters class="welcome-secundary-text">
        {{ $t('login.error_message_release_date') }}
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    releaseDate: {
      type: String,
      required: true,
    },
  },

  methods: {
    goBackToLogin() {
      this.$emit('go-back-to-login')
    },
  },
}
</script>

<style scoped>
.welcome-text {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}

.welcome-secundary-text {
  color: #51594f;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.platform-access-button {
  padding-left: 0 !important;
  background-color: transparent !important;
  box-shadow: none;

  color: #39af49;
  font-weight: 700;
  margin-bottom: 10px;
}

.platform-access-button:hover,
.platform-access-button::before {
  background-color: transparent !important;
}
</style>
